
import { message } from 'ant-design-vue';
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue';
import { getIncentive, getDealerByGroupId } from '@/API/salesReporting';
import moment from 'moment';
import { Moment } from 'moment';
import * as echarts from 'echarts';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { qOptions } from '@/views/SalesReporting/type';
export default defineComponent({
    setup() {
        const { state } = useStore();
        const store = useStore();
        const isDealer = ref(true);
        if ((store.state.user as any).legalEntity.entityCode !== 'Dealer') {
            isDealer.value = false;
        }
        const dealerCode = ref((state.user as any).organization.entityCode);

        const router = useRouter();
        const myChart = ref<any>();
        const myChartDom = ref<any>(null);
        const buList = ref<any[]>([]);
        const brandList = ref<any[]>([]);
        const brandArray = ref<any[]>([]);

        interface SearchDataType {
            bu: string;
            brand: string | null;
            year: string;
            quarter: Array<string>;
        }

        const searchData: SearchDataType = reactive({
            bu: '',
            brand: '',
            year: '',
            quarter: [],
        });

        const option = ref<any>({
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: [320, 330, 350, 380, 370, 310, 330],
                    name: 'OEM Check Status',
                    type: 'bar',
                    barWidth: 35,
                },
                {
                    data: [120, 200, 150, 80, 70, 110, 130],
                    name: 'Dealer Rejected',
                    barWidth: 10,
                    type: 'bar',
                    stack: 'total',
                },
                {
                    data: [120, 200, 150, 80, 70, 110, 130],
                    name: 'Quarter',
                    type: 'bar',
                    barWidth: 10,
                    stack: 'total',
                },
                {
                    data: [120, 200, 150, 80, 70, 110, 130],
                    name: 'Option',
                    type: 'bar',
                    barWidth: 10,
                    stack: 'total',
                },
            ],
        });

        const dataSource = ref<any[]>([]);
        const columns = [
            {
                title: '核查情况总览',
                dataIndex: 'checkResultMessage',
                key: 'checkResultMessage',
                align: 'center',
            },
            {
                title: '经销商核查结果确认情况',
                children: [
                    {
                        title: '经销商已确认',
                        dataIndex: 'accepted',
                        key: 'accepted',
                        align: 'center',
                    },
                    {
                        title: '经销商已拒绝',
                        dataIndex: 'rejected',
                        key: 'rejected',
                        align: 'center',
                    },
                    {
                        title: '经销商未确认',
                        dataIndex: 'communicated',
                        key: 'communicated',
                        align: 'center',
                    },
                ],
            },
        ];

        let first = true;
        const queryData = async () => {
            if (searchData.bu && searchData.year) {
                const brandA = [];
                if (searchData.brand === 'All') {
                    for (let i = 0; i < brandList.value.length; i++) {
                        if (brandList.value[i].brandNameEn != 'All') {
                            brandA.push(brandList.value[i].brandNameEn);
                        }
                    }
                } else {
                    brandA.push(searchData.brand);
                }
                const array: Array<string> = [];
                if (searchData.quarter.length) {
                    for (let i = 0; i < searchData.quarter.length; i++) {
                        array.push(
                            searchData.year + '-' + searchData.quarter[i][1]
                        );
                    }
                } else {
                    array.push(searchData.year + '-' + '1');
                    array.push(searchData.year + '-' + '2');
                    array.push(searchData.year + '-' + '3');
                    array.push(searchData.year + '-' + '4');
                }
                console.log('array', array);

                const dealerCodeArray: Array<string> = [];
                if (isDealer.value) {
                    dealerCodeArray.push(dealerCode.value);
                } else {
                    await getDealerByGroupId(
                        (store.state.user as any).orgid
                    ).then((res) => {
                        for (let i = 0; i < res.length; i++) {
                            dealerCodeArray.push(res[i].dealerInfo.dealerCode);
                        }
                    });
                }

                const currentBu = buList.value.find((i: any) => {
                    return i.id === searchData.bu;
                });
                const isSecondBu = currentBu.parentId !== null;
                let firstBuName = '';
                let secondBuName = '';
                if (!isSecondBu) {
                    firstBuName = currentBu.nameEn;
                } else {
                    secondBuName = currentBu.nameEn;
                    const parentId = currentBu.parentId;
                    firstBuName = buList.value.find((i: any) => {
                        return i.id === parentId;
                    }).nameEn;
                }

                const param = {
                    currentDealerCodes: dealerCodeArray,
                    option: 2,
                    brandName: brandA,
                    communicationReqVo: {
                        bus: [firstBuName],
                        secondeBus: secondBuName !== '' ? [secondBuName] : [],
                        programPeriodYear: searchData.year,
                        programPeriodQuarter: array,
                    },
                };
                console.log(param);
                const unwarp = (obj: any) =>
                    obj && (obj.__v_raw || obj.valueOf() || obj);
                getIncentive(param).then((res) => {
                    unwarp(myChart.value).setOption(res.option);
                    if (first) {
                        unwarp(myChart.value).setOption(res.option);
                        first = false;
                    }
                    const data = res.table;
                    for (let i = 0; i < data.length; i++) {
                        data[i].checkResultMessage =
                            data[i].checkResultCn + ': ' + data[i].total;
                        data[i].accepted = data[i].accepted ?? '-';
                        data[i].communicated = data[i].communicated ?? '-';
                        data[i].rejected = data[i].rejected ?? '-';
                    }
                    dataSource.value = data;
                });
            } else {
                message.error('请选择查询项');
            }
        };
        const yearArray: Array<string> = [];
        for (let i = 5; i >= 0; i--) {
            yearArray.push(
                moment()
                    .subtract(i, 'year')
                    .format('YYYY')
            );
        }
        for (let i = 1; i <= 5; i++) {
            yearArray.push(
                moment()
                    .add(i, 'year')
                    .format('YYYY')
            );
        }

        const init = (buArray: Array<any>, brandArrayData: Array<any>) => {
            for (let i = 0; i < buArray.length; i++) {
                if (buArray[i].parentId === null) {
                    buList.value.push(buArray[i]);
                }
            }
            brandArray.value = brandArrayData;
            searchData.bu = buList.value[0].id;
            for (let i = 0; i < buArray.length; i++) {
                if ((buArray[i] as any).nameEn === 'MBPC') {
                    searchData.bu = (buArray[i] as any).id;
                }
            }

            let array: Array<any> = [];
            for (let i = 0; i < brandArray.value.length; i++) {
                if (searchData.bu === brandArray.value[i].buId) {
                    array = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }

            for (let i = 0; i < array.length; i++) {
                if (array[i].brandNameEn !== 'DENZA') {
                    brandList.value.push(array[i]);
                }
            }
            if (brandList.value.length) {
                searchData.brand = brandList.value[0].brandNameEn;
                brandList.value.push({
                    brandNameCn: 'All',
                    brandNameEn: 'All',
                    id: -1,
                });
            }
            searchData.brand = 'All';

            searchData.year = moment().format('YYYY');
            const q = 'Q' + moment().quarter();
            searchData.quarter = [q];
            myChart.value = echarts.init(myChartDom.value);

            queryData();
        };

        const tableHeight = ref(180);
        //period初始化
        const openProgramPeriodYear = ref<boolean>(false);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            searchData.year = time as string;
            openProgramPeriodYear.value = false;
        };

        const gotoDetail = () => {
            let str = '';
            for (let i = 0; i < searchData.quarter.length; i++) {
                str = str + searchData.quarter[i][1] + ',';
            }
            const param = {
                bu: searchData.bu,
                brand: searchData.brand,
                year: searchData.year,
                quarter: str.substring(0, str.length - 1),
            };
            router.push({
                path: `/salesreporting/claimCheckingReportDealer`,
                query: Object.assign({ type: 'overview' }, param),
            });
        };

        const getBrandList = (value: number) => {
            // console.log(value)
            searchData.brand = null;
            brandList.value = [];
            let array: Array<any> = [];
            for (let i = 0; i < brandArray.value.length; i++) {
                if (value === brandArray.value[i].buId) {
                    array = JSON.parse(
                        JSON.stringify(brandArray.value[i].brandList)
                    );
                }
            }

            for (let i = 0; i < array.length; i++) {
                if (array[i].brandNameEn !== 'DENZA') {
                    brandList.value.push(array[i]);
                }
            }
            if (brandList.value.length) {
                // searchData.brand = brandList.value[0].brandNameEn
                brandList.value.push({
                    brandNameCn: 'All',
                    brandNameEn: 'All',
                    id: -1,
                });
            }
            searchData.brand = 'All';
        };

        return {
            openProgramPeriodYear,
            handlerOpenProgramPeriodChange,
            handlerPanelProgramPeriodChange,
            init,
            brandList,
            buList,
            yearArray,
            gotoDetail,
            myChartDom,
            getBrandList,
            myChart,
            dataSource,
            columns,
            tableHeight,
            ...toRefs(searchData),
            queryData,
            qOptions,
        };
    },
});
